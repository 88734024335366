import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { lastValueFrom, take } from 'rxjs'
import { Store } from '@ngrx/store'
import { ISiteConfiguration, IProfile, IDealerProfileInfo } from '../interfaces/[CodeGen]/Interfaces'
import { State } from '@appState/reducers'
import { DealerPageActions } from '@appState/actions/dealer'
import { DealerService } from './dealer.service'


@Injectable({ providedIn: 'root' })
export class AppConfigProvider {
   private _configApi = '/api/config';

   config: ISiteConfiguration

   /**********************************

   THIS component NO LONGER USED since
   we don't call APP_INITIALIZER anymore
   to get site config - handled now in
   the AuthGuardService used in main
   appmodule route

   **********************************/
   get currentProfile(): IProfile {
      return this.config && this.config.currentProfile
   }

   constructor(
      private _dealerService: DealerService,
      private _httpClient: HttpClient) { }

   loadConfig() {
      const request$ = this._httpClient.get<ISiteConfiguration>(this._configApi).pipe(take(1))

      return lastValueFrom<ISiteConfiguration>(request$)
         .then((config): ISiteConfiguration => {

            this.config = {
               ...config,
               cacheBuster: (new Date()).getTime(),
               //contact: { dealer: {} }
            } as ISiteConfiguration

            const profile = config && config.currentProfile

            //this._store.dispatch(DealerPageActions.setCurrentProfile({ profile }));

            if (profile?.dealerInfo) {
               this._dealerService.setSelectedDealer(profile)
            }

            return this.config
         })
   }
}
