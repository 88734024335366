import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppActions, State } from '@appState/index'
import * as fromStore from '@appState/selectors/app.selectors'
import { IProfile } from '@appShared/interfaces/[CodeGen]/IProfile'
import { ISiteConfiguration } from '@appShared/interfaces/[CodeGen]/ISiteConfiguration'
//import { getSelectedDealer } from '@appState/selectors/dealer.selectors'

@Injectable({
   providedIn: 'root'
})
export class AppFacade {
   siteConfig$: Observable<ISiteConfiguration> = this._store.select(
      fromStore.getSiteConfig
   )

   currentProfile$: Observable<IProfile> = this._store.select(
      fromStore.getCurrentProfile
   )

   //selectedDealer$: Observable<IProfile | null> = this._store.select(
   //   getSelectedDealer
   //)

   salesPortalUri$: Observable<string> = this._store.select(
      fromStore.getSalesPortalUri
   )

   studentPortalLoginUri$: Observable<string> = this._store.select(
      fromStore.getStudentPortalLoginUri
   )

   constructor(private _store: Store<State>) { }

   loadSiteConfig() {
      this._store.dispatch(AppActions.loadSiteConfig())
   }

   prefetchSiteConfig(item) {
      // if haven't gotten store yet, go get it
      if (!item) {
         this.loadSiteConfig()
      }
   }
}
