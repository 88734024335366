import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, shareReplay } from 'rxjs/operators';
import { CommonService } from './common.service';
import {
  IDealerSearchResult,
  ICollege
} from '../interfaces/[CodeGen]/Interfaces';

@Injectable({
  providedIn: 'root'
})
export class MasterDataCacheService {
  private masterDataCacheApi = '/api/master-data-cache';
  colleges$: Observable<ICollege[]>;

  constructor(
    private _httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  getColleges(): Observable<ICollege[]> {
    const url = `${this.masterDataCacheApi}/colleges`;
    return this._httpClient
      .get<ICollege[]>(url)
      .pipe(
        tap(data =>
          this.commonService.logApi(this.masterDataCacheApi + 'colleges', data)
        ),
        shareReplay(1) /* cache top colleges */,
        catchError(this.commonService.handleError<ICollege[]>('getColleges'))
      );
  }

  dealerSearch(searchTerm: string): Observable<IDealerSearchResult[]> {
    //if (searchTerm) {
    //   const filteredDealers: IDealerSearchResult[] = this.fakeDealers.filter(dealer => {
    //      return dealer.dealerName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    //   });
    //   return of(filteredDealers);
    //} else {
    //   return of([]);
    //}
    let url = `${
      this.masterDataCacheApi
    }/dealer-search?searchTerm=${searchTerm}`;

    return this._httpClient
      .get<IDealerSearchResult[]>(url)
      .pipe(
        tap(data => this.commonService.logApi(url, data)),
        catchError(
          this.commonService.handleError<IDealerSearchResult[]>('dealerSearch')
        )
      );
  }

  getDealerInfo(sourceId: string): Observable<IDealerSearchResult> {
    let url = `${this.masterDataCacheApi}/dealer-search?searchTerm=${sourceId}`;

    return of({
      sourceId: sourceId,
      dealerName: 'Bubba Jones'
    } as IDealerSearchResult);
    //return this.httpClient.get<IDealerSearchResult>(url)
    //  .pipe(
    //    tap(data => this.commonService.logApi(url, data)),
    //    catchError(this.commonService.handleError<IDealerSearchResult>('getDealerInfo'))
    //  );
  }
}
