import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.less']
})

export class SiteFooterComponent {
   constructor(public router: Router) {}
}
