import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrType } from './toastr.service';
import { CommonService } from './common.service';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private _commonService: CommonService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const responseCodes = {
      ok: 200,
      redirect: 302,
      badRequest: 400,
      forbidden: 403,
      notFound: 404,
      methodNotAllowed: 405,
      appUpdateRequired: 444,
      internalServerError: 500
    };

    const authReq = req.clone({
      headers: req.headers
        .set('Cache-Control', 'no-cache, no-store, must-revalidate')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('If-Modified-Since', '0'),
      // Prevent caching in IE, in particular IE11.
      // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      },
      //modify all request URLs that started with http to start with https
      url: req.url.replace('http://', 'https://')
    });

    return next.handle(authReq).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.success)
            this._commonService.messageUser(
              evt.body.success.message,
              evt.body.success.title
            );

          //log api responses to client
          if (req.responseType === 'json') {
            this._commonService.logApi(
              req.urlWithParams,
              req.method,
              evt.body /* data */,
              req.body /* payload */
            );
          }
        }
      }),
      catchError((err: any) => {
        let errorMessage;

        if (err instanceof HttpErrorResponse) {
          let errorTitle;

          try {
            if (err && (err.error || err.message)) {
              const responseError = err.error;

              if (
                err.status === responseCodes.badRequest ||
                err.status === responseCodes.notFound ||
                err.status === responseCodes.methodNotAllowed
              ) {
                if (_.isString(responseError)) {
                  if (
                    responseError.startsWith('<!DOCTYPE') ||
                    responseError.startsWith('<html')
                  ) {
                    errorMessage =
                      'ERROR - An Unknown fatal error has occurred!';
                  } else {
                    errorMessage = responseError;
                  }
                } else if (
                  _.isObject(responseError) &&
                  responseError['message']
                ) {
                  errorMessage = responseError['message'];
                }
              } else if (err.status === responseCodes.internalServerError) {
                errorMessage = 'An unknown error has occurred';
              }

              errorMessage = errorMessage || 'An error occurred';
              errorTitle = responseError && responseError['title'];
            }
          } catch (e) {
            errorMessage = e;
          }

          this._commonService.messageUser(
            errorMessage || 'An unknown error has occurred',
            errorTitle || 'Error Occurred!',
            ToastrType.error
          );
          this._commonService.setLoading(false);
          this._commonService.logError(err, req.method, req.body /* payload */);
        }

        return throwError(errorMessage);
      })
    );
  }
}
