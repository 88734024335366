import { createAction, props } from '@ngrx/store';
import { ICollege } from '@appShared/interfaces/[CodeGen]/ICollege';

const eventPrefix = '[Master Data Cache API]';

export const loadColleges = createAction(`${eventPrefix} Load Colleges`);

export const loadCollegesSuccess = createAction(
  `${eventPrefix} Load Colleges Success`,
  props<{ colleges: ICollege[] }>()
);

export const loadCollegesFailure = createAction(
  `${eventPrefix} Load Colleges Failure`,
  props<{ error: string }>()
);
