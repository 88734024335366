<!--<div class="navbar navbar-expand-md navbar-dark site-header fixed-top">
  <div class="container" *ngIf="isAuthenticated">
    <a href="{{salesPortalUri$|async}}" class="btn btn-sm btn-success" style="color:white">Return Adv4Dealers</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse ps-3" id="navbarsExampleDefault">
      <ul class="navbar-nav me-auto selectedDealer">
        <li class="nav-item dropdown active">
          <ng-container *ngIf="selectedDealer$ | async as selectedDealer">
            <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{selectedDealer.contact.firstName}} {{selectedDealer.contact.lastName}} ( <span class="fw-bold">{{(selectedDealer.contact.sourceId)}}</span> )
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownDealer">
              <div *ngIf="selectedDealer$ | async as selectedDealer"
                   class="card card-body dealerInfo pt-2">
                <h5>{{selectedDealer.contact.firstName}} {{selectedDealer.contact.lastName}}</h5>
                <div class="media">
                  <img src="/api/dealer/{{selectedDealer.contact.sourceId}}/photo"
                       class="align-self-start me-3 img-rounded img-thumbnail outer-shadow" alt="..." />
                  <div class="media-body">
                    <div class="media-heading">( {{selectedDealer.contact.sourceId}} )</div>
                    <div>
                      <span class="fw-bold">Sales Manager:</span><br>
                      {{selectedDealer.contact.salesManagerName}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-2" *ngIf="(currentProfile$|async)?.canViewDealerSearch">
                <button (click)="searchDealers()" class="btn btn-sm btn-secondary text-white">Search Dealers</button>
              </div>
            </div>
          </ng-container>
        </li>
      </ul>
      <ul class="navbar-nav navbar-right ms-auto">
        <li class="nav-item dropdown active">
          <a class="nav-link dropdown-toggle" href="#" id=" " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">MENU</a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu">
            <a tabindex="-1" href="{{salesPortalUri$|async}}" class="dropdown-item">Adv4Dealers</a>
            <div *ngIf="(currentProfile$|async)?.canViewPendingMerchandiseReturns">
              <div class="dropdown-divider"></div>
              <a tabindex="-1" [routerLink]="['/merchandise-returns/pending']" class="logout dropdown-item">Pending Returms</a>
            </div>
            <div class="dropdown-divider"></div>
            <a tabindex="-1" [routerLink]="['/sales-school-registration/main']" class="logout dropdown-item">Sales School Reg.</a>
            <div class="dropdown-divider"></div>
            <a tabindex="-1" [routerLink]="['/']" class="logout dropdown-item">Dashboard</a>
            <div class="dropdown-divider"></div>
            <a tabindex="-1" href="/auth/signout" class="logout dropdown-item">Logout</a>
          </div>

        </li>
      </ul>
    </div>
  </div>
</div>-->
<nav class="navbar navbar-expand-md navbar-dark site-header fixed-top">
   <div class="container">
      <a href="{{salesPortalUri$|async}}" class="btn btn-sm btn-success me-3" style="color:white">Return Adv4Dealers</a>

      <!-- mobile toggle button-->
      <button class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
         <ul class="navbar-nav me-auto ms-md-2">
            <li *ngIf="!(selectedDealer$ | async)"
                class="nav-item active dropdown">
               <span *ngIf="(currentProfile$|async)?.canViewDealerSearch"
                       (click)="searchDealers()"
                       class="text-white pointable text-underline">Search Dealers</span>
            </li>
            <li *ngIf="selectedDealer$ | async as selectedDealer"
                class="nav-item dropdown selected-dealer">
               <a class="nav-link active dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{selectedDealer.contact.firstName}} {{selectedDealer.contact.lastName}} ( <span class="fw-bold">{{(selectedDealer.contact.sourceId)}}</span> )
               </a>
               <div class="dropdown-menu" aria-labelledby="dropdownDealer">
                  <div class="card mb-3 p-1">
                     <!--style="max-width: 540px;"-->
                     <div class="row g-0">
                        <div class="col-md-4">
                           <!--<img src="/api/dealer/{{selectedDealer.contact.sourceId}}/photo"
                                class="align-self-start me-3 img-rounded img-thumbnail outer-shadow" alt="..." />-->
                           <img src="/api/dealer/{{selectedDealer.contact.sourceId}}/photo"
                                class="img-fluid img-rounded img-thumbnail outer-shadow" alt="..." />
                        </div>
                        <div class="col-md-8">
                           <div class="card-body p-1">
                              <h5 class="card-title">{{selectedDealer.contact.firstName}} {{selectedDealer.contact.lastName}}</h5>
                              <p class="card-text dealer-account-number">( {{selectedDealer.contact.sourceId}} )</p>
                              <p class="card-text"><small class="text-body-secondary">{{selectedDealer.contact.salesManagerName}}</small></p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!--<div class="card card-body dealerInfo pt-2">
                     <h5>{{selectedDealer.contact.firstName}} {{selectedDealer.contact.lastName}}</h5>
                     <div class="media">

                        <div class="media-body">
                           <div class="media-heading">( {{selectedDealer.contact.sourceId}} )</div>
                           <div>
                              <span class="fw-bold">Sales Manager:</span><br>
                              {{selectedDealer.contact.salesManagerName}}
                           </div>
                        </div>
                     </div>
                  </div>-->
                  <div class="text-center mt-2" *ngIf="(currentProfile$|async)?.canViewDealerSearch">
                     <button (click)="searchDealers()" class="btn btn-sm btn-secondary text-white">Search Dealers</button>
                  </div>
               </div>
            </li>
         </ul>
         <ul class="navbar-nav">
            <li class="nav-item dropstart">
               <a class="nav-link active dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Menu
               </a>
               <ul class="dropdown-menu dropdown-menu-left">
                  <li>
                     <a tabindex="-1" href="{{salesPortalUri$|async}}" class="dropdown-item">Adv4Dealers</a>
                  </li>
                  <li *ngIf="(currentProfile$|async)?.canViewPendingMerchandiseReturns">
                     <div class="dropdown-divider"></div>
                     <a tabindex="-1" [routerLink]="['/merchandise-returns/pending']" class="logout dropdown-item">Pending Returms</a>
                  </li>
                  <li><hr class="dropdown-divider"></li>

                  <ng-container *ngIf="(selectedDealer$|async)?.dealerInfo">
                     <li>
                        <a tabindex="-1" [routerLink]="['/sales-school-registration/main']" class="logout dropdown-item">Sales School Reg.</a>
                     </li>
                     <li><hr class="dropdown-divider"></li>
                     <li>
                        <a tabindex="-1" [routerLink]="['/account/remit-online']" class="logout dropdown-item">Remit Online</a>
                     </li>
                     <li><hr class="dropdown-divider"></li>
                  </ng-container>

                  <li>
                     <a tabindex="-1" [routerLink]="['/']" class="logout dropdown-item">Dashboard</a>
                  </li>
                  <li *ngIf="(currentProfile$|async)?.canViewDealerSearch">
                     <div class="dropdown-divider"></div>
                     <span (click)="searchDealers()"
                           class="pointable dropdown-item">Search Dealers</span>
                  </li>
                  <li><hr class="dropdown-divider"></li>
                  <li>
                     <a tabindex="-1" href="/auth/signout" class="logout dropdown-item">Logout</a>
                  </li>
               </ul>
            </li>
         </ul>
      </div>
   </div>
</nav>
