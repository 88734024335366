import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TitleCasePipe } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
// https://ng-select.github.io/ng-select#/data-sources
import { NgSelectModule } from '@ng-select/ng-select'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
// https://github.com/text-mask/text-mask/tree/master/angular2
// TODO import { TextMaskModule } from 'angular2-text-mask';
// https://www.npmjs.com/package/ngx-cookie-service
import { CookieService } from 'ngx-cookie-service'
/// https://swimlane.github.io/ngx-datatable
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
// https://www.npmjs.com/package/ngx-mask
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask'
// https://www.npmjs.com/package/ngx-currency
import { NgxCurrencyDirective } from 'ngx-currency'
import { CreditCardDirectivesModule } from 'angular-cc-library'
import { PdfViewerModule } from 'ng2-pdf-viewer'
// https://www.npmjs.com/package/ng-recaptcha
/*
 * https://www.google.com/recaptcha/admin/site/346889429/settings
 * jeffscottyoung@gmail.com - jyoung@swgao.com - esolima@swgao.com
   Site_Key: 6LfVHK0UAAAAAIva485_o6z_pLOxbyzkIbkJaeqF
   Server_Key: 6LfVHK0UAAAAAIrka-TF_Hi38QfPQ80ZVJvNpdBO
*/
// import { RecaptchaModule } from 'ng-recaptcha';
// https://valor-software.com/ng2-file-upload/
// https://stackoverflow.com/questions/47936183/angular-file-upload/51328690#51328690
import { FileUploadModule } from 'ng2-file-upload'

import * as fromComponents from './components'
import * as fromDirectives from './directives'
import * as fromServices from './services'
import { Toastr } from './services'

const toastr: Toastr = window['toastr']
const moment = window['moment']
const jQuery = window['$']

@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NgxCurrencyDirective,
      NgxMaskDirective,
      NgxMaskPipe,
      NgSelectModule,
      /* TODO
        TextMaskModule,
        */
      NgxDatatableModule,
      FileUploadModule,
      BsDatepickerModule.forRoot(),
      CreditCardDirectivesModule,
      PdfViewerModule
   ],
   exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgxCurrencyDirective,
      NgxMaskDirective,
      NgxMaskPipe,
      /* TODO
          TextMaskModule,
          */
      NgSelectModule,
      NgxDatatableModule,
      FileUploadModule,
      BsDatepickerModule,
      CreditCardDirectivesModule,
      PdfViewerModule,
      ...fromDirectives.directives,
      ...fromComponents.components
   ],
   declarations: [
      ...fromDirectives.directives,
      ...fromComponents.components
   ],
   providers: [
      TitleCasePipe,
      { provide: fromServices.TOASTR_TOKEN, useValue: toastr },
      { provide: fromServices.MOMENT_TOKEN, useValue: moment },
      { provide: fromServices.JQUERY_TOKEN, useValue: jQuery },
      CookieService,
      provideEnvironmentNgxMask()
   ]
})
export class SharedModule { }
