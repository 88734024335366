<div class="card-header">
  <h2>
    <img src="https://advantage4dealers.com/images/advantage4dealers_blk_authServer.png" alt="Advantage 4 Dealers" class="logo"><ng-container *ngIf="selectedDealer$ | async as selectedDealer; else noDealer">
      <span class="small">
        : {{selectedDealer.contact.firstName}} {{selectedDealer.contact.lastName}} ( {{selectedDealer.contact.sourceId}} )
        <!--<img src="/api/dealer/{{(selectedDealer$|async)?.sourceId}}/photo"
        class="align-self-start me-3 img-rounded img-thumbnail outer-shadow dealer-pic" alt="..." />-->
      </span>
    </ng-container>

    <ng-template #noDealer>
      <button (click)="searchDealers()" class="btn btn-sm btn-site-secondary text-white ms-4"
              *ngIf="(currentProfile$|async)?.canViewDealerSearch">
        Search Dealers
      </button>
    </ng-template>
  </h2>
</div>
