import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { IProfile } from '@appShared/interfaces/[CodeGen]/Interfaces'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { FormControl } from '@angular/forms'

@Component({
   selector: 'app-dashboard',
   templateUrl: './app-dashboard.component.html',
   styles: [
      `
    .list-group {
      max-width: 530px;
      margin-left: auto;
      margin-right: auto;
    }
    .list-group-item {
      font-size: 24px;
      font-weight: bold;
      border: 1px solid #E7E0EB;
    }
    .logo {
      width: 450px;
    }
    .fa-icon { font-size:10rem!important }
     @media (max-width: 1024px) {
         .fa-icon { font-size:6rem!important }
      }
      @media (max-width: 768px) {
         .fa-icon { font-size:4rem!important }
      }
    `
   ]
})
export class AppDashboardComponent /* implements OnInit*/ {
   currentProfile$: Observable<IProfile>
   selectedDealer$: Observable<IProfile | null>
   salesPortalUri$: Observable<string>
   inputControl = new FormControl("");

   constructor(
      appFacade: AppFacade,
      dealerService: DealerService
   ) {
      this.currentProfile$ = appFacade.currentProfile$
      this.selectedDealer$ = dealerService.selectedDealer$
      this.salesPortalUri$ = appFacade.salesPortalUri$
   }

   //ngOnInit() {
   //  /*  https://weicheng95.medium.com/understanding-the-differences-between-angulars-rxjs-operators-switchmap-map-mergemap-761aedc52bdf
   //   *  switchMap is useful for scenarios where you want to see only the latest data
   //      mergeMap is useful when merging multiple responses
   //      concatMap is useful when you want to maintain the order of the results
   //      exhaustMap is useful for disabling observables from being re-triggered.
   //   * */

   //  /* switchMap */
   //  /*
   //  this.inputControl.valueChanges
   //    .pipe(
   //      // use switchMap to cancel any previous requests
   //      switchMap((value: any) => {
   //        if (!value) {
   //          // if query is empty, return an empty result
   //          return of([])
   //        }

   //        // make the AJAX request
   //        return this._authService.getSiteConfig()
   //      })
   //    )
   //    .subscribe(
   //      (results) => console.log('results',results),
   //      (error) => console.error(error)
   //  )
   //  */

   //  /*  MergeMap is similar to switchMap, but instead of canceling pending observables,
   //   *  it merges them all together. This makes it useful for scenarios where you want
   //   *  to combine multiple responses from a server into a single observable stream.

   //      The mergeMap operator works by subscribing to each inner observable and then
   //      merging the emitted values from each inner observable onto the final observable stream.
   //      If all the inner observables are complete, the mergeMap operator completes as well.
   //   * */
   //  /*
   //  const source = of(66721, 66762, 66787)
   //  const example = source.pipe(
   //    mergeMap((val) =>
   //      this._dealerService.getDealer(val)
   //    )
   //  )
   //  example.subscribe((res) => console.log(res));
   //  */

   //  /*  ConcatMap is an RxJS operator used to sequentially concatenate an observable with
   //   *  multiple inner observables, without performing any parallel request. It subscribes
   //   *  to one observable at a time, combining the responses in order into a single observable stream.

   //      Imagine you need to make a sequence of HTTP requests where each response depends on
   //      the previous one. In this case, you can use the concatMap operator to maintain the order
   //      of the responses, so that each response is processed only when the previous response has been completed.
   //      This prevents any race conditions when handling multiple HTTP requests simultaneously.

   //      We use forkJoin to ensure that all observables are complete before we combine the results
   //      together as a single stream
   //   * */
   //  /*
   //  const source2 = of([{ sourceId: 66721 }, { sourceId: 66762 }, { sourceId: 66787 }, { sourceId: 66762 }])
   //  const example2 = source2.pipe(
   //    concatMap((dealers: any[]) => {
   //      const uniqueDealerIds:number[] = Array.from(
   //        new Set(dealers.map((dealer) => dealer.sourceId))
   //      )
   //      const observables = uniqueDealerIds.map((dealerId) =>
   //        this._httpClient.get(`https://localhost:44349/api/sales-school-registration/${dealerId}`)
   //      )
   //      return forkJoin(observables)
   //    })
   //  )
   //  example2.subscribe((data) => {
   //    console.log('dealers SS registration:',data)
   //  })
   //  */

   //  /* ExhaustMap is an RxJS operator that is used to temporarily disable an Observable from
   //   * being re-triggered until it has been completed. It works by ignoring any subsequent emissions
   //   * from the source Observable while the inner Observable is still in progress. Once the inner
   //   * Observable completes, the next emission from the source Observable will start a new inner Observable.

   //     One use case for exhaustMap is in handling user events, such as button clicks. For example, if a button
   //     on a web page is clicked multiple times in quick succession, each click event will trigger an HTTP request.
   //     If you only want the initial click to trigger the request, and temporarily disable the button until
   //     the response is received, you can use exhaustMap.

   //    In this example, fromEvent is used to create an observable that emits a input event every time the
   //    user types a new character in the search bar. The exhaustMap operator is then used to map each input
   //    event to an HTTP request using fetch. If the user types too quickly, only the first HTTP request will
   //    be made, and subsequent requests will be ignored until the first request completes.
   //   *
   //   *  https://dummyapis.com/
   //   * */

   //  /*
   //  setTimeout(() => {
   //    const searchInput = document.querySelector("#search-bar")
   //    console.log(searchInput)
   //    fromEvent(searchInput, "input")
   //      .pipe(
   //        exhaustMap((event: any) => {
   //          return fetch(

   //            `https://hub.dummyapis.com/delay?seconds=1`
   //          ).then(() => true)
   //        })
   //      )
   //      .subscribe((results) => {
   //        // Display search results to user
   //        console.log('results',results)
   //      })
   //  })
   //  */
   //}
}
