import { createAction, props } from '@ngrx/store';
import { IProfile } from '@appShared/interfaces/[CodeGen]/IProfile';

const apiLevelEventPrefix = '[Dealer Page API]';

//export const loadSelectedDealerSuccess = createAction(
//  `${apiLevelEventPrefix} Load Selected Dealer Success`,
//  props<{ profile: IProfile }>()
//);

//export const loadSelectedDealerFailure = createAction(
//  `${apiLevelEventPrefix} Load Selected Dealer Failure`,
//  props<{ error: string }>()
//);
