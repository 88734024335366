import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';

export const components: any[] = [
  AppDashboardComponent,
  NavMenuComponent,
  PageNotFoundComponent,
  SiteFooterComponent
];

export * from './app-dashboard/app-dashboard.component';
export * from './nav-menu/nav-menu.component';
export * from './page-not-found.component';
export * from './site-footer/site-footer.component';
