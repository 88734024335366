import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AuthGuardService } from './shared'
import { AppDashboardComponent, PageNotFoundComponent } from './components'

const routes: Routes = [
   {
      path: 'account',
      loadChildren: () =>
         import('./account/account.module').then(
            mod => mod.AccountModule
         )
   },
   {
      path: 'student-dealer',
      loadChildren: () =>
         import('./representative/representative.module').then(
            mod => mod.RepresentativeModule
         )
   },
   {
      path: 'sales-school-registration',
      loadChildren: () =>
         import('./sales-school-registration/sales-school-registration.module').then(
            mod => mod.SalesSchoolRegistrationModule
         )
   },
   {
      path: 'merchandise-returns',
      loadChildren: () =>
         import('./merchandise-returns/merchandise-returns.module').then(
            mod => mod.MerchandiseReturnsModule
         )
   },
   {
      path: 'home',
      component: AppDashboardComponent,
      canActivate: [AuthGuardService]
   },
   {
      path: '404',
      component: PageNotFoundComponent
   },
   { path: '', redirectTo: 'home', pathMatch: 'full' },
   { path: '**', redirectTo: '404', pathMatch: 'full' }
]

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})
export class AppRoutingModule { }
