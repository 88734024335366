import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommonService } from './common.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ISiteConfiguration } from '../interfaces/[CodeGen]/Interfaces';
import { AppFacade } from './app.facade';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    private _commonService: CommonService,
    private _appFacade: AppFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    //https://levelup.gitconnected.com/ngrx-and-angular-route-guards-a3fc3d5255f8
    return this._appFacade.siteConfig$.pipe(
      tap(siteConfig => this._appFacade.prefetchSiteConfig(siteConfig)),
      filter(data => !!data), //filter till has data
      take(1), //now that is has filtered data - take 1
      switchMap(config => {
        if (!(config.currentProfile?.userPrincipalId)) {
        const returnUrl = this._commonService.getResolvedUrl(route);
        this._commonService.navigateToLogin(returnUrl);
        return of(false);
      }

    return of(true);
      }),
      catchError(() => of(false))
    );
  }
}
