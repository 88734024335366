import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MasterDataCacheService } from '@appShared/services/master-data-cache.service';
import { MasterDataCacheActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class MasterDataCacheEffects {
  constructor(
    private _actions$: Actions,
    private _masterDataCacheService: MasterDataCacheService
  ) {}

  loadColleges$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(MasterDataCacheActions.loadColleges),
      switchMap(action =>
        this._masterDataCacheService
          .getColleges()
          .pipe(
            map(colleges =>
              MasterDataCacheActions.loadCollegesSuccess({ colleges })
            ),
            catchError(error =>
              of(MasterDataCacheActions.loadCollegesFailure({ error }))
            )
          )
      )
    );
  });
}
