import { ButtonComponent } from './button/button.component';
import { ConfirmModalComponent } from './confirm-modal-and-service';
import { DealerSearchComponent } from './dealer-search/dealer-search.component';
import { ModalDealerSearchComponent } from './dealer-search/modal-dealer-search.component';
import { ModalComponent } from './modal/modal.component';
import { SelectedDealerPageHeaderComponent } from './selected-dealer-page-header/selected-dealer-page-header.component';
import { SimpleModalComponent } from './simple-modal.component';

export const components: any[] = [
   ButtonComponent,
  ConfirmModalComponent,
  DealerSearchComponent,
  ModalComponent,
  ModalDealerSearchComponent,
  SelectedDealerPageHeaderComponent,
  SimpleModalComponent
];

export * from './button/button.component'
export * from './confirm-modal-and-service';
export * from './dealer-search/dealer-search.component';
export * from './dealer-search/modal-dealer-search.component';
export * from './modal/modal.component';
export * from './selected-dealer-page-header/selected-dealer-page-header.component';
export * from './simple-modal.component';
