import { createSelector } from '@ngrx/store'
import { getDealerFeatureState } from '../reducers/dealer.reducer'

//export const getItemState = createSelector(
//  fromFeature.getProfileFeatureState,
//  (state: fromFeature.ProductsAdminState) => state.items
//);

//export const getCurrentProfile = createSelector(
//  getProfileFeatureState,
//  state => state.currentProfile
//);

//export const getSelectedDealer = createSelector(
//  getDealerFeatureState,
//  state => state.selectedDealer
//);
