<section class="bg-dark text-white">
   <ul class="nav ms-2">
      <li class="nav-item">
         <a class="nav-link text-white" [routerLink]='["/contact"]'>Start a Fundraiser</a>
      </li>
      <li class="nav-item">
         <a class="nav-link text-white" [routerLink]='["/how-it-works"]'>How It Works</a>
      </li>
      <li class="nav-item">
         <a class="nav-link text-white" [routerLink]='["/why-us"]'>Why Us?</a>
      </li>
      <li class="nav-item">
         <a class="nav-link text-white" [routerLink]='["/contact"]'>Contact Us</a>
      </li>
      <li class="nav-item">
         <a class="nav-link text-white" [routerLink]='["/privacy-policy"]'>Privacy Policy</a>
      </li>
      <li class="nav-item">
         <a class="nav-link text-white" [routerLink]='["/terms-of-use"]'>Terms of Use</a>
      </li>
   </ul>
</section>
