import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { IProfile } from '@appShared/interfaces/[CodeGen]/IProfile';
//import { DealerApiActions, DealerPageActions } from '../actions/dealer';

export interface DealerState {
  selectedDealer: IProfile;
  errorSetSelectedDealer: string;
}

const intialDealerState: DealerState = {
  selectedDealer: null,
  errorSetSelectedDealer: ''
};

export const getDealerFeatureState = createFeatureSelector<DealerState>(
  'dealer'
);

export const dealerReducer = createReducer<DealerState>(
  intialDealerState
  //on(DealerPageActions.setSelectedDealer, (state, action): DealerState => {
  //  return {
  //    ...state,
  //    selectedDealer: action.profile
  //  };
  //}),
  //on(
  //  DealerApiActions.loadSelectedDealerSuccess,
  //  (state, action): DealerState => {
  //    return {
  //      ...state,
  //      selectedDealer: action.profile,
  //      errorSetSelectedDealer: ''
  //    };
  //  }
  //),
  //on(
  //  DealerApiActions.loadSelectedDealerFailure,
  //  (state, action): DealerState => {
  //    return {
  //      ...state,
  //      selectedDealer: null,
  //      errorSetSelectedDealer: action.error
  //    };
  //  }
  //)
);
