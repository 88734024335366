import { createSelector } from '@ngrx/store';
import { getAppFeatureState } from '../reducers/app.reducer';

//export const getItemState = createSelector(
//  fromFeature.getProfileFeatureState,
//  (state: fromFeature.ProductsAdminState) => state.items
//);

export const getSiteConfig = createSelector(
  getAppFeatureState,
  state => state?.config
);

export const getCurrentProfile = createSelector(
  getSiteConfig,
  state => state?.currentProfile
);

export const getSalesPortalUri = createSelector(
  getSiteConfig,
  state => state?.salesPortalUri
);

export const getStudentPortalLoginUri = createSelector(
  getSiteConfig,
  state => state?.studentPortalLoginUri
);
