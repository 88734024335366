import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params
} from '@angular/router';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';
import * as fromDealer from './dealer.reducer';
import * as fromApp from './app.reducer';
import * as fromMasterDataCache from './master-data-cache.reducer';

export interface RouterStateUrl {
  /*can extend more if needed */
  url: string;
  queryParams: Params;
  params: Params;
}

export interface State {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  masterDataCache: fromMasterDataCache.MasterDataCacheState;
  dealer: fromDealer.DealerState;
  app: fromApp.AppState;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer,
  masterDataCache: fromMasterDataCache.masterDataCacheReducer,
  dealer: fromDealer.dealerReducer,
  app: fromApp.appReducer
};

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>('router-reducer');

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    //have to traverse state tree to get params
    let state: ActivatedRouteSnapshot = routerState.root;
    //can pull any values from "state"
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}
