import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { AuthService } from '@appShared/services/auth.service'
import { AppActions } from '../actions'
import { DealerService } from '@appShared/services/dealer.service'

@Injectable()
export class AppEffects {
   constructor(
      private _actions$: Actions,
      private _authService: AuthService,
      private _dealerService: DealerService) { }

   loadSiteConfig$ = createEffect(() => {
      return this._actions$.pipe(
         ofType(AppActions.loadSiteConfig),
         switchMap(action =>
            this._authService.getSiteConfig().pipe(
               switchMap(siteConfig => {

                  const profile = siteConfig?.currentProfile
                  const selectedDealerProfile = profile?.dealerInfo ? profile : null

                  let actions = []

                  /* add setSelectedDealer IF currentProfile is a dealer */
                  if (selectedDealerProfile) {
                     this._dealerService.setSelectedDealer(profile)
                  }

                  actions.push(AppActions.loadSiteConfigSuccess({ siteConfig }))

                  return actions
               }),
               catchError(error => of(AppActions.loadSiteConfigFailure({ error })))
            )
         )
      )
   });
}
