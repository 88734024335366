import { NgModule /*, APP_INITIALIZER*/ } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
/* https://weblog.west-wind.com/posts/2019/Apr/07/Creating-a-custom-HttpInterceptor-to-handle-withCredentials */
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
/* TODO - remove this when we get real server stuff */
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { SharedModule } from './shared/shared.module'
import { Router, Scroll, Event } from '@angular/router'
import { ViewportScroller } from '@angular/common'
import { filter } from 'rxjs/operators'
import { HttpRequestInterceptor } from './shared'

import { MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { storeFreeze } from 'ngrx-store-freeze'
import { environment } from 'src/environments/environment'
import { EffectsModule } from '@ngrx/effects'

import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router'

import { reducers, effects, CustomSerializer } from './state'
import {
   RouterStateSerializer,
   StoreRouterConnectingModule
} from '@ngrx/router-store'

export const metaReducers: MetaReducer<any>[] = !environment.production
   ? [storeFreeze]
   : []

export default class CustomUrlSerializer implements UrlSerializer {
   private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

   parse(url: string): UrlTree {
      // Encode "+" to "%2B"
      url = url.replace(/\+/gi, '%2B')
      // Use the default serializer.
      return this._defaultUrlSerializer.parse(url)
   }

   serialize(tree: UrlTree): string {
      return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B')
   }
};

@NgModule({
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      CoreModule,
      SharedModule,
      AppRoutingModule,
      NgbModule,
      StoreModule.forRoot(reducers, {
         metaReducers
      }),
      EffectsModule.forRoot(effects),
      StoreRouterConnectingModule.forRoot(),
      environment.production
         ? []
         : StoreDevtoolsModule.instrument({
            name: 'SWP App Devtools',
            maxAge: 25,
            logOnly: true /*environment.production*/
         })
   ],
   declarations: [
      AppComponent
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpRequestInterceptor,
         multi: true
      },
      {
         provide: RouterStateSerializer,
         useClass: CustomSerializer
      },
      {
         provide: UrlSerializer,
         useClass: CustomUrlSerializer
      }
   ],
   bootstrap: [AppComponent]
})
export class AppModule {
   constructor(private router: Router, viewportScroller: ViewportScroller) {
      /* https://angular.io/api/router/ExtraOptions */
      this.router.events
         .pipe(filter((e: Event): e is Scroll => e instanceof Scroll))
         .subscribe(e => {
            //console.log('app-event', e);
            if (e.position) {
               // backward navigation
               viewportScroller.scrollToPosition(e.position)
            } else if (e.anchor) {
               // anchor navigation
               viewportScroller.scrollToAnchor(e.anchor)
            } else {
               // forward navigation
               viewportScroller.scrollToPosition([0, 0])
            }
         })
   }
}
