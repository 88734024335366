import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { ICollege } from '@appShared/interfaces/[CodeGen]/ICollege';
import { MasterDataCacheActions } from '../actions';

export interface MasterDataCacheState {
  colleges: ICollege[];
  error: string;
}

const intialMasterDataCacheState: MasterDataCacheState = {
  colleges: null,
  error: ''
};

export const getMasterDataCacheFeatureState = createFeatureSelector<
  MasterDataCacheState
>('masterDataCache');

export const masterDataCacheReducer = createReducer<MasterDataCacheState>(
  intialMasterDataCacheState,
  on(
    MasterDataCacheActions.loadCollegesSuccess,
    (state, action): MasterDataCacheState => {
      return {
        ...state,
        colleges: action.colleges,
        error: ''
      };
    }
  ),
  on(
    MasterDataCacheActions.loadCollegesFailure,
    (state, action): MasterDataCacheState => {
      return {
        ...state,
        colleges: null,
        error: action.error
      };
    }
  )
);
