import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { ISiteConfiguration } from '@appShared/interfaces/[CodeGen]/ISiteConfiguration';
import { AppActions } from '../actions';

export interface AppState {
  config: ISiteConfiguration;
  error: string;
}

const intialAppState: AppState = {
  config: null,
  error: ''
};

export const getAppFeatureState = createFeatureSelector<AppState>('app');

export const appReducer = createReducer<AppState>(
  intialAppState,
  on(AppActions.loadSiteConfigSuccess, (state, action): AppState => {
    return {
      ...state,
      config: action.siteConfig,
      error: ''
    };
  }),
  on(AppActions.loadSiteConfigFailure, (state, action): AppState => {
    return {
      ...state,
      config: null,
      error: action.error
    };
  }),
  on(AppActions.setCurrentProfile, (state, action): AppState => {
    const currentProfile = action.profile;
    return {
      ...state,
      config: { ...state.config, currentProfile }
    };
  })
);
