
<div class=" mt-4">

   <div class="d-flex flex-column justify-content-center align-items-center">

      <img src="/assets/images/advantage4dealers_blk_authServer.png" alt="Advantage 4 Dealers" class="logo img-fluid mb-3">

      <div class="d-flex flex-wrap justify-content-around w-75 mt-5">

         <a *ngIf="(currentProfile$ | async)?.canViewPendingMerchandiseReturns"
            [routerLink]="['/merchandise-returns/pending']"
            class="mb-5 mb-lg-0 mb-lg-3 text-center text-site-secondary">
            <div class="text-center fa-icon">
               <i class="fa-regular fa-file-lines fa-fw"></i>
            </div>
            <h3 class="text-center">
               Pending<br />Returns
            </h3>
         </a>

         <a *ngIf="(selectedDealer$|async)?.dealerInfo && !((selectedDealer$|async)?.dealerInfo?.isEurope1 || (selectedDealer$|async)?.dealerInfo?.isInternationalB)"
            [routerLink]="['/sales-school-registration/main']"
            class="mb-5 mb-lg-0 mb-lg-3 text-center text-site-secondary">
            <div class="text-center fa-icon">
               <i class="fa-solid fa-pen-to-square fa-fw"></i>
            </div>
            <h3 class="text-center">
               Sales School<br />Registration
            </h3>
         </a>

         <!--<a *ngIf="(selectedDealer$|async)?.dealerInfo" class="mb-5 mb-lg-0 mb-lg-3 text-center text-site-secondary"
      [routerLink]="['/account']">
      <div class="text-center fa-icon">
         <i class="fa-regular fa-user"></i>-->
         <!--<i class="fa-solid fa-money-bill-transfer"></i>-->
         <!--</div>
      <h3 class="text-center">
         Manage<br />Dealer<br />Account
      </h3>
   </a>-->
         <a *ngIf="(selectedDealer$|async)?.dealerInfo" class="mb-5 mb-lg-0 mb-lg-3 text-center text-site-secondary"
            [routerLink]="['/account/remit-online']">
            <div class="text-center fa-icon">
               <i class="fa-solid fa-money-bill-transfer fa-fw"></i>
            </div>
            <h3 class="text-center">
               Remit<br />Online
            </h3>
         </a>

         <a href="{{salesPortalUri$|async}}" class="mb-5 mb-lg-0 mb-lg-3 text-center text-site-secondary">
            <div class="text-center fa-icon">
               <i class="fa-regular fa-address-book fa-fw"></i>
            </div>
            <h3 class="text-center">
               Advantage<br/>4-Dealers
            </h3>
         </a>

      </div>

   </div>

</div>
