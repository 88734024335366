import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonService } from '../services/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'simple-modal',
  template: `
    <div id="{{elementId}}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ng-content></ng-content>
          </div>
          <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" *ngIf="showSave">Save changes</button>
        </div>
        </div>
      </div>
    </div>
  `
})
export class SimpleModalComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() elementId: string | number;
  @Input() iFrameId: string | number;
  @Input() showSave: boolean;
  @Input() closeFunction: any;
  $thisModal: any;

  constructor(
    private common: CommonService,
    public activeModal: NgbActiveModal
  ) {}

  ngAfterViewInit() {
    let elementId = this.elementId || new Date().getTime();

    this.$thisModal = $(`#${elementId}`);

    // if they give an elementId and pass a closeFunction
    // then attach to modal close event
    if (this.closeFunction) {
      let self = this;
      this.$thisModal.on('hidden.bs.modal', function(e) {
        self.common.execute(self.closeFunction);
      });
    }
  }

  ngOnDestroy() {
    if (this.$thisModal) {
      this.$thisModal.modal('dispose');
    }
  }

  showModal() {
    if (this.$thisModal) {
      this.$thisModal.modal('show');
    }
  }

  hideModal() {
    if (this.$thisModal) {
      this.$thisModal.modal('hide');
    }
  }
}
