import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"',
    '[style.overflow]': '"hidden"'
  }
})
export class ModalComponent {
  @Input() title = ``;
  @Input() showSave: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() closeFunction: Function | null;
  @Input() dismissFunction: Function | null;
  @Input() saveFunction: Function | null;

  constructor(public activeModal: NgbActiveModal) {}

  dismiss() {
    this.activeModal.dismiss(this.closeFunction);
  }

  close() {
    this.activeModal.close(this.dismissFunction);
  }

  save() {
    this.activeModal.close(this.saveFunction);
  }
}
