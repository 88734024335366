/* SERVICES */
import { AppConfigProvider } from './app-config-provider.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { CommonService } from './common.service';
import { DateTimeService } from './date-time.service';
import { DealerService } from './dealer.service';
import { HttpRequestInterceptor } from './http-request-interceptor';
import { LookupService } from './lookup/lookups.service';
import { MasterDataCacheService } from './master-data-cache.service';

export const services: any[] = [
  AppConfigProvider,
  AuthService,
  AuthGuardService,
  CommonService,
  DateTimeService,
  DealerService,
  HttpRequestInterceptor,
  LookupService,
  MasterDataCacheService
];

export * from './app-config-provider.service';
export * from './auth.service';
export * from './auth-guard.service';
export * from './common.service';
export * from './date-time.service';
export * from './dealer.service';
export * from './http-request-interceptor';
export * from './lookup/lookups.service';
export * from './master-data-cache.service';

/* TOKENS */
import { JQUERY_TOKEN } from './JQuery.service';
import { MOMENT_TOKEN } from './moment.service';
import { TOASTR_TOKEN } from './toastr.service';

export { JQUERY_TOKEN, MOMENT_TOKEN, TOASTR_TOKEN };

export * from './JQuery.service';
export * from './moment.service';
export * from './toastr.service';
