import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, Event, NavigationEnd } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { IProfile } from '@appShared/interfaces/[CodeGen]/IProfile'
import { ModalDealerSearchComponent } from '@appShared/components/dealer-search/modal-dealer-search.component'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'

@Component({
   selector: 'app-nav-menu',
   templateUrl: './nav-menu.component.html',
   styleUrls: ['./nav-menu.component.less']
})
export class NavMenuComponent implements OnDestroy {
   isExpanded = false
   currentProfile$: Observable<IProfile>
   salesPortalUri$: Observable<string>
   selectedDealer$: Observable<IProfile | null>
   private routerSubscription: Subscription
   isAuthenticated: boolean

   constructor(
      router: Router,
      appFacade: AppFacade,
      private _modalService: NgbModal,
      private _dealerService: DealerService
   ) {
      this.currentProfile$ = appFacade.currentProfile$
      this.salesPortalUri$ = appFacade.salesPortalUri$
      this.selectedDealer$ = this._dealerService.selectedDealer$
      //this.currentProfile = appConfigProvider.currentProfile;
      //this.salesPortalUri =
      //  appConfigProvider.config && appConfigProvider.config.salesPortalUri;

      this.routerSubscription = router.events
         .pipe(
            filter((routerEvent: Event) => routerEvent instanceof NavigationEnd)
         )
         .subscribe((routerEvent: NavigationEnd) => {
            //var programDetailRegEx = /^\/group\/\d+(\/\d+\/?)?$/
            //const urlRoute = /[^/]*$/.exec(url)[0]
            //this.isAuthenticated = !_.includes(['verification', 'profile'], urlRoute)
            const url = routerEvent.url.toLowerCase()
            this.isAuthenticated = !url.startsWith('/student-dealer')
         })
   }

   ngOnDestroy() {
      this.routerSubscription.unsubscribe()
   }

   //private checkRouterEvent(routerEvent: Event): void {
   //  if (routerEvent instanceof NavigationEnd) {
   //    //var programDetailRegEx = /^\/group\/\d+(\/\d+\/?)?$/
   //    const urlRoute = /[^/]*$/.exec(routerEvent.url)[0]
   //    //this.isAuthenticated = !_.includes(['dealer-verification'], urlRoute)
   //    this.isAuthenticated = !urlRoute.startsWith('dealer-verification')
   //  }
   //}

   // TODO - may add this back
   //showSeach() {
   //   let url = this.router.url.toLowerCase()
   //   return !url.match('/group/[0-9]+')
   //}

   //showLogin() {
   // let url = this.router.url.toLowerCase()
   //  return !url.match('/welcome')
   //}

   collapse() {
      this.isExpanded = false
   }

   toggle() {
      this.isExpanded = !this.isExpanded
   }

   searchDealers() {
      const editLeadModalRef = this._modalService.open(
         ModalDealerSearchComponent,
         { scrollable: true }
      )
   }
}
