import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  filter,
  take
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DealerPageActions } from '@appState/actions/dealer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataCacheService } from '@appShared/services/master-data-cache.service';
import { DealerService } from '@appShared/services/dealer.service';
import { CommonService } from '@appShared/services/common.service';
import { IDealerSearchResult } from '@appShared/interfaces/[CodeGen]/IDealerSearchResult';
import { State } from '@appState/reducers';

@Component({
  selector: 'app-dealer-search',
  templateUrl: './dealer-search.component.html',
  styleUrls: ['./dealer-search.component.less']
})
/** dealer-search component*/
export class DealerSearchComponent implements OnInit {
  @ViewChild('dealerSearchTermInput', { static: true })
  dealerSearchTermElement: ElementRef;
  searchedTerm: string;
  searching: boolean;
  dealerSearchTerm: FormControl;
  dealers$: Observable<IDealerSearchResult[]>;

  constructor(
    private _store: Store<State>,
    private _masterDataService: MasterDataCacheService,
    private _dealerService: DealerService,
    private _activeModal: NgbActiveModal,
    private _commonService: CommonService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.dealerSearchTerm = new FormControl();

    // https://codecraft.tv/courses/angular/http/http-with-observables/
    this.dealers$ = this.dealerSearchTerm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(searchTerm => searchTerm.length > 1) /* more than 1 character */,
      tap(_ => (this.searching = true)),
      switchMap(term => this._masterDataService.dealerSearch(term)),
      tap(_ => (this.searching = false))
    );

    if (this.dealerSearchTermElement) {
      this.dealerSearchTermElement.nativeElement.focus();
    }
  }

  setSelectedDealer(selectedDealer: IDealerSearchResult) {
    this._dealerService
      .getDealer(selectedDealer.dealerSalesSessionId)
      .pipe(take(1))
       .subscribe(dealerProfile => {
        this._dealerService.setSelectedDealer(dealerProfile)

        if (this._router.url === '/sales-school-registration/main') {
          this._commonService.scrollToPosition([0, 0]);
        } else {
           //TODO - pass to account shell once we get more modules moved
          //this._router.navigate(['/account']);
        }

        this._activeModal.close();
      });
  }
}
