import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@appShared/shared.module'
import { AppRoutingModule } from '../app-routing.module';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@appEnvironments/environment';
import { EffectsModule } from '@ngrx/effects';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import * as fromComponents from '../components';

/* extend the existing window interface to tell it about your new property. */
declare global {
  interface Window {
    sw_portal: any;
  }

  interface Date {
    stdTimezoneOffset(): number;

    isDstObserved(): boolean;
  }
}

window.sw_portal = window.sw_portal || { api_history: [], errors: [] };

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      name: 'SWP App DevTools',
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([])
  ],
  exports: [
    ...fromComponents.components,
    NgbModule,
    BsDatepickerModule
  ],
  declarations: [
    ...fromComponents.components
  ],
  providers: [
    NgbActiveModal
  ]
})
export class CoreModule {}

Date.prototype.stdTimezoneOffset = function(): number {
  var jan = new Date(this.getFullYear(), 0, 1);
  var jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function(): boolean {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};
