import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISiteConfiguration } from '../interfaces/[CodeGen]/ISiteConfiguration';
import { AppFacade } from './app.facade';
//import { HttpClient } from '@angular/common/http';
//import { catchError, map, shareReplay, tap } from 'rxjs/operators';
//import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authApi: string = '/auth/';

  constructor(private _httpClient: HttpClient) {}

  getSiteConfig(): Observable<ISiteConfiguration> {
    return this._httpClient.get<ISiteConfiguration>('api/config');
  }
}
