import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DealerService } from '@appShared/services/dealer.service'
import { AppFacade } from '@appShared/services/app.facade'
import { ModalDealerSearchComponent } from '@appShared/components/dealer-search/modal-dealer-search.component'
import { IProfile } from '@appShared/interfaces/[CodeGen]/IProfile'

@Component({
   selector: 'app-selected-dealer-page-header',
   templateUrl: './selected-dealer-page-header.component.html',
   styles: [
      `
    img.dealer-pic {
      width: 55px;
      height: 70px;
      padding: 0;
    }

    .logo {
      width: 245px;
    }`
   ]
})
export class SelectedDealerPageHeaderComponent implements OnInit {
   currentProfile$: Observable<IProfile>
   selectedDealer$: Observable<IProfile | null>
   selectedDealer2$: Observable<IProfile | null>

   constructor(
      appFacade: AppFacade,
      private _modalService: NgbModal,
      private _dealerService: DealerService
   ) {
      this.currentProfile$ = appFacade.currentProfile$
   }

   ngOnInit() {
      this.selectedDealer$ = this._dealerService.selectedDealer$// this._store.select(getSelectedDealer);
   }

   searchDealers() {
      const editLeadModalRef = this._modalService.open(
         ModalDealerSearchComponent,
         {
            scrollable: true
         }
      )
   }
}
