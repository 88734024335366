import { createAction, props } from '@ngrx/store';
import {
  IProfile,
  ISiteConfiguration
} from '@appShared/interfaces/[CodeGen]/Interfaces';

const eventPrefix = '[App API]';

export const loadSiteConfig = createAction(`${eventPrefix} Set Site Config`);

export const loadSiteConfigSuccess = createAction(
  `${eventPrefix} Load Site Config Success`,
  props<{ siteConfig: ISiteConfiguration }>()
);

export const loadSiteConfigFailure = createAction(
  `${eventPrefix} Load Site Config Failure`,
  props<{ error: string }>()
);

export const setCurrentProfile = createAction(
  `${eventPrefix} Set Current Profile`,
  props<{ profile: IProfile }>()
);
