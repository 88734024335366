import { ConfirmTemplateDirective } from '../components/confirm-modal-and-service'
import { ModalTriggerDirective } from './modal-trigger.directive'

export const directives: any[] = [
   ModalTriggerDirective,
   ConfirmTemplateDirective
]

export * from '../components/confirm-modal-and-service'
export * from './modal-trigger.directive'
